/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';

const withLazyLoadSkeleton = ( WrappedComponent, SkeletonComponent ) => {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  // eslint-disable-next-line react/prefer-stateless-function
  class EnhancedComponent extends Component {
    render() {
      const { across, pristine, products, skeletonClass } = this.props;

      if ( pristine ) {
        return <SkeletonComponent across={across} className={skeletonClass} />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  EnhancedComponent.displayName = `withLazyLoadSkeleton(${componentName})`;

  return EnhancedComponent;
};

export default withLazyLoadSkeleton;
